div.thumbnailImg {
  width: 203px;
  height: 203px;
  margin: 2px 2px 2px 2px;
  background-repeat: no-repeat;
  background-position: center center;
}

div.thumbnailImgContainer {
  width: 207px;
  height: 207px;
  overflow: hidden;
}

div.fullimage {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  height: calc(70vh);
  margin-bottom: 16px;
}

div.share-link {
  margin-bottom: 24px;
}

div.login-link {
  text-align: center;
}

div.show {
  visibility: visible;
}

div.hide {
  visibility: hidden;
}

.header-title {
  line-height: 1;
  border-bottom: 1px solid #e5e5e5;
}

.header-title-text {
  font-family: "Helvetica", sans-serif;
  font-size: 2.25rem;
}

.nav-scroller {
  position: relative;
  z-index: 2;
  height: 2.75rem;
  overflow-y: hidden;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  padding-bottom: 1rem;
  margin-top: -1px;
  overflow-x: auto;
  text-align: center;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
}

img.google {
  width: 300px
}

img.email {
  height: 48.566px
}

img.disconnected {
  height: 1em;
}

img.colander {
  height: 1.5em;
  margin-right: 10px;
}